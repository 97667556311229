<template>
  <section-wrapper
    class="overflow-visible pt-16 max-md:pb-32 md:pt-32 md:pb-64"
    background="/images/ROUTE.jpg"
  >
    <section-layer class="bg-secondary mix-blend-screen"></section-layer>
    <section-layer class="bg-secondary mix-blend-multiply"></section-layer>
    <section-container
      mobile-padding
      class="max-w-container relative z-10 text-center"
    >
      <slot></slot>
    </section-container>
  </section-wrapper>
</template>
